import { render, staticRenderFns } from "./SearchLocationCard.vue?vue&type=template&id=7021f956&scoped=true&"
import script from "./SearchLocationCard.vue?vue&type=script&lang=js&"
export * from "./SearchLocationCard.vue?vue&type=script&lang=js&"
import style0 from "./SearchLocationCard.vue?vue&type=style&index=0&id=7021f956&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7021f956",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonIcon: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/common/Icon/index.vue').default,LocationsSearchElementsOperationInfo: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/locations/search/elements/OperationInfo.vue').default,LocationsSearchElementsAddressInfo: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/locations/search/elements/AddressInfo.vue').default,LocationsSearchElementsPhoneInfo: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/locations/search/elements/PhoneInfo.vue').default,CommonHandoffModeSelector: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/common/HandoffModeSelector/index.vue').default})
