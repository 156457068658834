import { render, staticRenderFns } from "./Default.vue?vue&type=template&id=2425bfac&"
import script from "./Default.vue?vue&type=script&lang=js&"
export * from "./Default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonRadarMap: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/common/RadarMap/index.vue').default,CommonOrderHeader: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/common/OrderHeader/index.vue').default,CommonHandoffModeSelector: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/common/HandoffModeSelector/index.vue').default,CommonLocationSearch: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/common/LocationSearch/index.vue').default,CommonLocationSelected: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/common/LocationSelected.vue').default,CommonLocationDeliveryForm: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/common/LocationDeliveryForm/index.vue').default,CommonSavedAddresses: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/common/SavedAddresses/index.vue').default,CommonLocationCurbsideForm: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/common/LocationCurbsideForm/index.vue').default,CommonOrderTimePicker: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/common/OrderTimePicker.vue').default,CommonLocationReservation: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/common/LocationReservation.vue').default})
