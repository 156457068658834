
import { mapState, mapMutations, mapActions } from 'vuex'

// import ClockIcon from '../../_globals/vectors/ClockIcon.vue'
// import HeartIcon from '../../_globals/vectors/HeartIcon.vue'
// import HeartFilledIcon from '../../_globals/vectors/HeartFilledIcon.vue'
// import DeliveryIcon from '../../_globals/vectors/DeliveryIcon.vue'
// import PickupIcon from '../../_globals/vectors/PickupIcon.vue'
// import ReservationIcon from '../../_globals/vectors/ReservationIcon.vue'

export default {
  name: 'SearchLocationCard',
  components: {
    // Icon,
    // HandoffModeSelector,
    // LocationPhoneInfo,
    // LocationAddressInfo,
    // LocationOperationInfo,
    // ClockIcon,
    // HeartIcon,
    // HeartFilledIcon,
    // DeliveryIcon,
    // PickupIcon,
    // ReservationIcon,
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    showHandoffModeSelector: {
      type: Boolean,
      default: false,
    },
    showChip: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => {
        return {
          separator: '|',
          showServicesOnCard: false,
          showServicesOnOperation: true,
          locationPageAction: 'View Restaurant Details',
          locationPageIcon: '',
        }
      }
    },
  },
  computed: {
    ...mapState([ 'user', 'appInfo', 'cart' ]),
    ...mapState({
      favLocs: state => state.members.favLocs,
      handoffMode: state => state.order.handoffMode,
      loading: state => state.loading,
    }),
    appHandoffModes() {
      return this.appInfo.order['handoff_modes']
    },
    locationHandoffModes() {
      if (this.location) {
        return this.$utils.locationHandoffModes(this.location, this.appHandoffModes)
      }

      return []
    },
    locationDisabled() {
      return !this.data.services[this.handoffMode]
    },
    services() {
      if (this.config && this.config.showServicesOnCard && this.data && this.data.services) {
        const services = []
        if (this.data.services.delivery) {
          services.push({
            name: 'Delivery',
            iconName: 'delivery-icon',
            iconClasses: 'delivery-icon',
          })
        }
        if (this.data.services.pickup) {
          services.push({
            name: 'Pickup',
            iconName: 'pickup-icon',
            iconClasses: 'pickup-icon',
          })
        }
        if (this.data.services.curbside) {
          services.push({
            name: 'Curbside',
            iconName: 'curbside-icon',
            iconClasses: 'curbside-icon',
          })
        }
        if (this.data.services.drivethru) {
          services.push({
            name: 'Drive Thru',
            iconName: 'curbside-icon',
            iconClasses: 'curbside-icon',
          })
        }
        return services
      }

      return []
    },
  },
  methods: {
    ...mapMutations([ 'setLocation', 'setLoading', 'setMenu' ]),
    ...mapActions([ 'sendRequest', 'transferCart', 'trackEvent' ]),
    ...mapActions({
      saveFavLoc: 'members/saveFavLoc',
      deleteFavLoc: 'members/deleteFavLoc',
    }),
    async favorite() {
      const favLocFound = this.favLocs.find(l => l.id === this.data.id)
      if (favLocFound){
        await this.deleteFavLoc(this.data.menu_id)
      } else {
        const response = await this.saveFavLoc(this.data.menu_id)
        if (response) {
          this.trackEvent({
            name: 'location_favorited',
            data: this.data,
            windowObject: window
          })
        }
      }
    },
    handleSelectLocation() {
      // Transfer cart when changing locations
      if (this.cart && this.cart.menu_id !== this.data.menu_id) {
        this.transferCart(this.data.menu_id)
      }
      if(this.data) {
        this.trackEvent({
          name: 'select_location',
          data: this.data,
          windowObject: window
        })
      }
      this.setLocation(this.data)
      this.setMenu(null)
    },
  },
}
