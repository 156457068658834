
import { mapState, mapMutations, mapActions } from 'vuex'
import LocationResults from '@/components/locations/search/elements/LocationResults.vue'


export default {
  name: 'LocationsSearchViewDefault',
  props: {
    blok: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    LocationResults
  },
  computed: {
    ...mapState([ 'location', 'user', 'appInfo' ]),
    ...mapState({
      handoffMode: state => state.order.handoffMode,
      orderMode: state => state.order.orderMode,
      selectedDay: state => state.order.selectedDay,
      selectedTime: state => state.order.selectedTime,
      vehicleInfo: state => state.locations.vehicleInfo,
      deliveryAddress: state => state.locations.deliveryAddress,
    }),
    deliveryAction() {
      return {
        show: true,
        text: 'Order Delivery',
        icon: 'delivery-icon',
        classes: 'btn primary',
      }
    },
    curbsideAction() {
      return {
        show: true,
        text: 'Save',
        classes: 'btn primary',
      }
    },
    startOrderDisabled() {
      if (this.orderMode === 'asap') return false

      if (this.selectedDay === null || this.selectedTime === null) return true

      return false
    },
    locationSelectedAction() {
      return {
        show: true,
        classes: 'btn primary link',
        text: 'Change location',
        icon: 'navigation-icon',
        callback: this.handleClose,
      }
    },
    appHandoffModes() {
      return this.appInfo.order['handoff_modes']
    },
    appSubHandoffModes() {
      if (this.appHandoffModes && this.appHandoffModes.length > 0) {
        const handoffFound = this.appHandoffModes.find(handoff => handoff.sub_handoff_modes_keys && handoff.sub_handoff_modes_keys.includes(this.handoffMode))
        if (handoffFound && handoffFound.sub_handoff_modes && handoffFound.sub_handoff_modes.length > 0) {
          return handoffFound.sub_handoff_modes
        }
      }
      return []
    },
    startOrderCta() {
      return this.orderMode === 'asap' ? 'Start Your Order' : 'Schedule Order'
    },
    backgroundImg() {
      if (this.showMap) return {}

      if (this.blok && this.blok['hero_image'] && this.blok['hero_image'].filename) {
        return { 'background-image': `url('${this.blok['hero_image'].filename}')` }
      }

      return {}
    },
    showMap() {
      if (this.handoffMode === 'delivery' && this.blok && this.blok['hero_image'] && this.blok['hero_image'].filename) {
        return false
      }
      return true
    },
    showLocationSearch() {
      const handoffModes = ['pickup', 'curbside', 'drivethru', 'reservation']
      return !this.location && handoffModes.includes(this.handoffMode)
    },
    showPickupAndLocation() {
      const handoffModes = ['pickup', 'curbside', 'drivethru', 'reservation']
      return this.location && handoffModes.includes(this.handoffMode)
    },
    showCurbsideAndLocation() {
      return this.location && this.handoffMode === 'curbside'
    },
    showSavedAddresses() {
      return this.user && this.handoffMode === 'delivery' && !this.deliveryAddress
    },
    showReservation() {
      return this.location && this.handoffMode === 'reservation'
    },
    showOrderTimePicker() {
      if (['pickup', 'drivethru'].includes(this.handoffMode)) {
        return this.location
      }
      if (this.handoffMode === 'delivery') {
        return this.deliveryAddress
      }
      if (this.handoffMode === 'curbside') {
        return this.location && this.vehicleInfo
      }
      return false
    },
    showStartOrder() {
      return this.showOrderTimePicker
    },
  },
  mounted() {
    if (this.user) {
      Promise.all([ this.getFavLocs(), this.getAddresses() ]);
    }
  },
  methods: {
    ...mapMutations([ 'setLocation', 'setMenu' ]),
    ...mapMutations({
      setDeliveryAddress: 'locations/setDeliveryAddress',
      setVehicleInfo: 'locations/setVehicleInfo',
    }),
    ...mapActions([ 'createCart' ]),
    ...mapActions({
      getFavLocs: 'members/getFavLocs',
      getAddresses: 'members/getAddresses',
    }),
    handleClose() {
      if (this.location) {
        this.setLocation(null)
        this.setMenu(null)
      }
      if (this.deliveryAddress) {
        this.setDeliveryAddress(null)
      }
      if (this.vehicleInfo) {
        this.setVehicleInfo(null)
      }
    },
    async startOrder() {
      if (this.location && this.location.menu_id) {
        await this.createCart()
        this.$router.push(`/menu/${this.location.menu_id}`)
      }
    }
  },
}
